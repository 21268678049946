@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  text-transform: capitalize;
  transition: 0.2s linear;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-padding-top: 9rem;
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  width: 0.8rem;
}

html::-webkit-scrollbar-track {
  background: transparent;
}

html::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 5rem;
}

body {
  background: #010103;
  overflow-x: hidden;
}

section {
  padding: 2rem 7%;
}

.heading {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  padding-bottom: 3.5rem;
  font-size: 4rem;
}

.heading span {
  color: #ffc107;
  text-transform: uppercase;
}

.btn {
  margin-top: 1rem;
  display: inline-block;
  padding: 0.9rem 3rem;
  font-size: 1.7rem;
  color: #fff;
  background: #ffc107;
  cursor: pointer;
}

.btn:hover {
  letter-spacing: 0.2rem;
}

.header {
  background: #010103;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 7%;
  border-bottom: 0.2rem solid #ffc107;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.header .logo img {
  max-width: 75px;
}

.header .navbar {
  display: flex; 
  flex-grow: 1; 
  justify-content: center; 
}

.header .navbar a {
  margin: 0 1rem;
  font-size: 1.6rem;
  color: #fff;
}

.header .navbar a:hover {
  color: #ffc107;
  border-bottom: 0.1rem solid #ffc107;
  padding-bottom: 0.5rem;
}

.header .icons div {
  color: #fff;
  cursor: pointer;
  font-size: 2.5rem;
  margin-left: 2rem;
}

.header .icons div:hover {
  color: #ffc107;
}


/* Mobile Styles */
@media (max-width: 768px) {
  html {
    font-size: 56.25%; 
  }

  .heading {
    font-size: 3rem; 
  }

  .btn {
    font-size: 1.4rem; 
    padding: 0.7rem 2rem;
  }

  .header .navbar a {
    font-size: 1.4rem; 
  }
}



/*
#menu-btn {
  display: none;
}

.header .search-form {
  position: absolute;
  top: 115%;
  right: 7%;
  background: #fff;
  width: 50rem;
  height: 5rem;
  display: flex;
  align-items: center;
  transform: scaleY(0);
  transform-origin: top;
}

.header .search-form.active {
  transform: scaleY(1);
}

.header .search-form input {
  height: 100%;
  width: 100%;
  font-size: 1.6rem;
  color: #13131a;
  padding: 1rem;
  text-transform: none;
}

.header .search-form label {
  cursor: pointer;
  font-size: 2.2rem;
  margin-right: 1.5rem;
  color: #13131a;
}

.header .search-form label:hover {
  color: #ffc107;
}

/*Home page css*/ 

.home {
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, rgb(0 0 0 / 90%), rgb(68 68 68 / 35%)),
    url(../images/home1.jpg) no-repeat;
  background-size: cover;
  background-position: center;
}

.home .content {
  max-width: 60rem;
}

.home span {
  color: #ffc107;
  text-transform: uppercase;
}

.home .content h3 {
  font-size: 6rem;
  text-transform: uppercase;
  color: #fff;
}

.home .content p {
  font-size: 2rem;
  font-weight: lighter;
  line-height: 1.8;
  padding: 1rem 0;
  color: #eee;
  max-height: none; 
  overflow: visible; 
  text-overflow: clip; 
}

/*About page css*/ 

.about .row {
  display: flex;
  align-items: center;
  background: #13131a;
  flex-wrap: wrap;
}

.about .row .image {
  flex: 1 1 45rem;
}

.about .row .image img {
  width: 100%;
}

.about .row .content {
  flex: 1 1 45rem;
  padding: 2rem;
}

.about .row .content h3 {
  font-size: 3rem;
  color: #fff;
}

.about .row .content p {
  font-size: 1.6rem; 
  color: #ccc; 
  padding: 1rem 0; 
  line-height: 1.8; 
  max-height: none;
  overflow: visible; 
  text-overflow: clip; 
}

/*product page css*/ 

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  text-transform: capitalize;
  transition: 0.2s linear;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-padding-top: 9rem;
  scroll-behavior: smooth;
}

body {
  background: #010103;
  overflow-x: hidden;
}

section {
  padding: 2rem 7%;
}

.heading {
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  padding-bottom: 3.5rem;
  font-size: 4rem;
}

.heading span {
  color: #ffc107;
  text-transform: uppercase;
}

.box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 1.5rem;
}

.box {
  border: 1px solid #444;
  border-radius: 10px;
  padding: 15px;
  background-color: #222; 
  text-align: center;
  max-width: 320px; 
  transition: transform 0.3s, box-shadow 0.3s; 
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); 
}

.box img {
  width: 100%; 
  height: auto; 
  border-radius: 10px; 
  transition: transform 0.3s;
  max-height: 400px; 
}

.box:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3); 
}

h2 {
  font-size: 2em; 
  margin: 15px 0; 
  color: #fff; 
}

p {
  font-size: 20px; 
  color: #bbb; 
  line-height: 1.5;
  max-height: none; 
  overflow: visible; 
  text-overflow: clip; 
}

@media (max-width: 991px) {
  html {
    font-size: 55%;
  }

  section {
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .box-container {
    flex-direction: column; 
    align-items: center; 
  }

  .box {
    max-width: 90%; 
  }

  .heading {
    font-size: 2.5em; 
  }

  h2 {
    font-size: 1.8em;
  }

  p {
    font-size: 1em; 
  }
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }
}
/*contact page css*/ 

.contact {
  padding: 5rem 2rem;
  background: #13131a;
}

.contact .heading {
  text-align: center;
  font-size: 3.5rem;
  color: #ffc107;
  margin-bottom: 2rem;
}

.contact .row {
  display: flex;
  justify-content: center;
  background: #13131a;
  flex-wrap: wrap;
  gap: 1rem;
}

.contact .row .info {
  text-align: center;
  color: #fff;
  font-size: 1.8rem;
  background: #13131a;
  padding: 5rem 2rem;
  border: 0.2rem solid #ffc107;
  flex: 1 1 45rem;
}

.contact .row .info h3 {
  font-size: 2.5rem;
  margin-top: 2rem;
  color: #ffc107;
}

.contact .row .info p {
  font-size: 1.8rem;
  color: #fff;
  text-decoration: none;
  margin: 0.5rem 0;
}

.contact .row .info .btn {
  background: #ffc107;
  color: #13131a;
  padding: 1rem 2rem;
  font-size: 1.7rem;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
  margin-top: 1.5rem; /* Space between phone number and button */
}

.contact .row .info .btn:hover {
  background: #e0a800;
}




/*Footer page css*/ 
.footer {
  background: #13131a;
  text-align: center;
}

.footer .share {
  padding: 1rem 0;
}

.footer .share a {
  display: inline-block; 
  height: 5rem; 
  width: 5rem; 
  margin: 0.3rem; 
}

.footer .share img {
  height: 100%; 
  width: 100%; 
  border-radius: 50%; 
  transition: background-color 0.3s; 
}

.footer .share a:hover img {
  background-color: #ffc107; 
}

.footer .links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 2rem 0;
  gap: 1rem;
}

.footer .links a {
  padding: 0.7rem 2rem;
  color: #fff;
  border: 0.2rem solid #ffc107;
  font-size: 2rem;
}

.footer .links a:hover {
  background: #ffc107;
}

.footer .credit {
  font-size: 2rem;
  color: #fff;
  font-weight: lighter;
  padding: 1.5rem;
}

.footer .credit span {
  color: #ffc107;
}
